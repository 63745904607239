import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const config = useRuntimeConfig()
	const sentry = config.public.sentry

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }), 
      Sentry.replayIntegration()
    ],
		
    tracesSampleRate: 0.2,
    tracePropagationTargets: ['localhost', config.public.DOMAIN],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
})