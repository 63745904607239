export function useValidatedSupabaseSession(): ComputedRef<null | {
		user: ReturnType<typeof useSupabaseUser>['value'],
		provider_token: string | undefined,
    provider_refresh_token: string | undefined,
    access_token: string | undefined,
    refresh_token: string | undefined,
    expires_in: number | undefined,
    expires_at: number | undefined,
    token_type: string | undefined,
	}
> {
	const session = useSupabaseSession()
	const user = useSupabaseUser()

	if(!session.value?.refresh_token || !user.value?.user_metadata) return computed(() => null)

	return computed(() => ({
		user: user.value,
		provider_token: session.value?.provider_token,
    provider_refresh_token: session.value?.provider_refresh_token,
    access_token: session.value?.access_token,
    refresh_token: session.value?.refresh_token,
    expires_in: session.value?.expires_in,
    expires_at: session.value?.expires_at,
    token_type: session.value?.token_type,
	}))
}