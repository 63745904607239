import revive_payload_client_9zPaq8AFAr from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WlEdHY8r9z from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UgdOdyTG6b from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_WzJQQejGQV from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_3nXd5y8Unq from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DeHLaieBRu from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3sTlBFkLVY from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8JSsKVtr2b from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_GUqUYC44mh from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ziYcKwnJdq from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import auth_redirect_DK1Gr9Su4J from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import sentry_client_shVUlIjFLk from "/app/apps/web/plugins/sentry.client.ts";
export default [
  revive_payload_client_9zPaq8AFAr,
  unhead_WlEdHY8r9z,
  router_UgdOdyTG6b,
  supabase_client_WzJQQejGQV,
  payload_client_3nXd5y8Unq,
  navigation_repaint_client_DeHLaieBRu,
  check_outdated_build_client_3sTlBFkLVY,
  chunk_reload_client_8JSsKVtr2b,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GUqUYC44mh,
  plugin_ziYcKwnJdq,
  auth_redirect_DK1Gr9Su4J,
  sentry_client_shVUlIjFLk
]