import { type RouterConfig } from '@nuxt/schema'
import { type RouteRecordRaw } from 'vue-router'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: _routes => {
    treatRoute(_routes)
    
    return _routes
  },
}

function treatRoute(routes: readonly RouteRecordRaw[]) {
  for (const route of routes) {
    route.path = route.path.replaceAll('_s_', '/')
    if (route.children) treatRoute(route.children)
  }
}
