<script setup lang="ts">
defineOptions({
  name: 'error',
})

const errorMessages = {
  404: {
    title: 'Oops! Page Not Found',
    message: 'The page you are trying to access doesn’t exist or has been removed.',
    image: '/404.svg',
  },
  403: {
    title: 'Forbidden Access',
    message: 'You don’t have permission to access this page.',
    image: '/403.svg',
  },
  500: {
    title: 'Internal Server Error',
    message: 'Something went wrong on our end. Please try again later.',
    image: '/500.svg',
  },
}

const nuxtApp = useNuxtApp()
const error = nuxtApp.payload.error

if (!error || ![404, 403, 500].includes(error.statusCode)) {
  showError(error)
}

const isDev = computed(() => import.meta.dev)
</script>

<template>
  <div v-if="[404, 403, 500].includes(error?.statusCode)" class="bg-white h-dvh fixed inset-0 flex">
    <LazySideMenu></LazySideMenu>
    <div class="flex flex-col items-center gap-12 justify-center h-screen mx-4 w-full">
      <img :src="errorMessages[error.statusCode].image" class="w-[369.7px]" />

      <div class="flex flex-col items-center text-center gap-6">
        <div class="flex flex-col items-center max-w-[484px] text-center gap-4">
          <div class="font-semibold text-[28px]">
            {{ errorMessages[error.statusCode].title }}
          </div>

          <div class="text-gray-600 font-normal text-xl">
            {{ errorMessages[error.statusCode].message }}
          </div>
        </div>
        <div v-if="isDev" class="w-[800px] text-start">
          <span class="block w-full mb-3">{{ error.cause }}</span>
          <pre class="h-[200px] overflow-scroll text-sm">
            {{ error.stack }}
          </pre>
        </div>

        <Button
          v-if="![500].includes(error?.statusCode)"
          @click="$router.push('/')"
          variant="default"
          class="flex h-9 py-2 px-5 rounded-3xl bg-gray-900"
        >
          <span>Go home</span>
        </Button>
      </div>
    </div>
  </div>
</template>
