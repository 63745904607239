import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_ioredis@5.4.1_sass-embedded@1.83.0_supports-color@9.4.0_typescript@5.5.4_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/apps/web/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}